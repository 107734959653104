/* This tool to convert fonts https://transfonter.org/ */
@font-face {
    font-family: 'Neo Sans Pro';
    src: local('Neo Sans Pro Light'), local('NeoSansPro-Light'),
        url('NeoSansPro-Light.woff2') format('woff2'),
        url('NeoSansPro-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Neo Sans Pro';
    src: local('Neo Sans Pro Regular'), local('NeoSansPro-Regular'),
        url('NeoSansPro-Regular.woff2') format('woff2'),
        url('NeoSansPro-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Neo Sans Pro';
    src: local('Neo Sans Pro Medium'), local('NeoSansPro-Medium'),
        url('NeoSansPro-Medium.woff2') format('woff2'),
        url('NeoSansPro-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Neo Sans Pro';
    src: local('Neo Sans Pro Bold'), local('NeoSansPro-Bold'),
        url('NeoSansPro-Bold.woff2') format('woff2'),
        url('NeoSansPro-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Neo Sans Pro';
    src: local('Neo Sans Pro Black'), local('NeoSansPro-Black'),
        url('NeoSansPro-Black.woff2') format('woff2'),
        url('NeoSansPro-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Next LT Arabic';
    src: local('DIN Next LT Arabic Light'), local('DINNextLTArabic-Light'),
        url('DINNextLTArabic-Light.woff2') format('woff2'),
        url('DINNextLTArabic-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'DIN Next LT Arabic';
    src: local('DIN Next LT Arabic Regular'), local('DINNextLTArabic-Regular'),
        url('DINNextLTArabic-Regular.woff2') format('woff2'),
        url('DINNextLTArabic-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'DIN Next LT Arabic';
    src: local('DIN Next LT Arabic Medium'), local('DINNextLTArabic-Medium'),
        url('DINNextLTArabic-Medium.woff2') format('woff2'),
        url('DINNextLTArabic-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'DIN Next LT Arabic';
    src: local('DIN Next LT Arabic Bold'), local('DINNextLTArabic-Bold'),
        url('DINNextLTArabic-Bold.woff2') format('woff2'),
        url('DINNextLTArabic-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'DIN Next LT Arabic';
    src: local('DIN Next LT Arabic Black'), local('DINNextLTArabic-Black'),
        url('DINNextLTArabic-Black.woff2') format('woff2'),
        url('DINNextLTArabic-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}
